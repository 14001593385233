._section-selection{
    width: 90%;
    max-width: 448px;
}

._constraint-manipulation {
    width: 90%;
    max-width: 786px;
    min-height: 448px;
}

._api-view {
    width: 90%;
    max-width: 992px;
    min-height: 448px;
}

._table-card{
    width: 100%;
    max-width: 315px;
}
