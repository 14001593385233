._size-fixed{
	resize: none;
}

._w-fit-content{
	width: fit-content;
}

._w-max-content{
	width: max-content;
}

._min-height-full-screen{
	min-height: 100vh;
}

._h-fit{
	height: 100%;
}


._half-screen{
	height: 50vh;
}

._w-1-pct{
	width: 1%;
}


._w-2-pct{
	width: 2%;
}


._w-3-pct{
	width: 3%;
}


._w-4-pct{
	width: 4%;
}


._w-5-pct{
	width: 5%;
}


._w-6-pct{
	width: 6%;
}


._w-7-pct{
	width: 7%;
}


._w-8-pct{
	width: 8%;
}


._w-9-pct{
	width: 9%;
}


._w-10-pct{
	width: 10%;
}


._w-11-pct{
	width: 11%;
}


._w-12-pct{
	width: 12%;
}


._w-13-pct{
	width: 13%;
}


._w-14-pct{
	width: 14%;
}


._w-15-pct{
	width: 15%;
}


._w-16-pct{
	width: 16%;
}


._w-17-pct{
	width: 17%;
}


._w-18-pct{
	width: 18%;
}


._w-19-pct{
	width: 19%;
}


._w-20-pct{
	width: 20%;
}


._w-21-pct{
	width: 21%;
}


._w-22-pct{
	width: 22%;
}


._w-23-pct{
	width: 23%;
}


._w-24-pct{
	width: 24%;
}


._w-25-pct{
	width: 25%;
}


._w-26-pct{
	width: 26%;
}


._w-27-pct{
	width: 27%;
}


._w-28-pct{
	width: 28%;
}


._w-29-pct{
	width: 29%;
}


._w-30-pct{
	width: 30%;
}


._w-31-pct{
	width: 31%;
}


._w-32-pct{
	width: 32%;
}


._w-33-pct{
	width: 33%;
}


._w-34-pct{
	width: 34%;
}


._w-35-pct{
	width: 35%;
}


._w-36-pct{
	width: 36%;
}


._w-37-pct{
	width: 37%;
}


._w-38-pct{
	width: 38%;
}


._w-39-pct{
	width: 39%;
}


._w-40-pct{
	width: 40%;
}


._w-41-pct{
	width: 41%;
}


._w-42-pct{
	width: 42%;
}


._w-43-pct{
	width: 43%;
}


._w-44-pct{
	width: 44%;
}


._w-45-pct{
	width: 45%;
}


._w-46-pct{
	width: 46%;
}


._w-47-pct{
	width: 47%;
}


._w-48-pct{
	width: 48%;
}


._w-49-pct{
	width: 49%;
}


._w-50-pct{
	width: 50%;
}


._w-51-pct{
	width: 51%;
}


._w-52-pct{
	width: 52%;
}


._w-53-pct{
	width: 53%;
}


._w-54-pct{
	width: 54%;
}


._w-55-pct{
	width: 55%;
}


._w-56-pct{
	width: 56%;
}


._w-57-pct{
	width: 57%;
}


._w-58-pct{
	width: 58%;
}


._w-59-pct{
	width: 59%;
}


._w-60-pct{
	width: 60%;
}


._w-61-pct{
	width: 61%;
}


._w-62-pct{
	width: 62%;
}


._w-63-pct{
	width: 63%;
}


._w-64-pct{
	width: 64%;
}


._w-65-pct{
	width: 65%;
}


._w-66-pct{
	width: 66%;
}


._w-67-pct{
	width: 67%;
}


._w-68-pct{
	width: 68%;
}


._w-69-pct{
	width: 69%;
}


._w-70-pct{
	width: 70%;
}


._w-71-pct{
	width: 71%;
}


._w-72-pct{
	width: 72%;
}


._w-73-pct{
	width: 73%;
}


._w-74-pct{
	width: 74%;
}


._w-75-pct{
	width: 75%;
}


._w-76-pct{
	width: 76%;
}


._w-77-pct{
	width: 77%;
}


._w-78-pct{
	width: 78%;
}


._w-79-pct{
	width: 79%;
}


._w-80-pct{
	width: 80%;
}


._w-81-pct{
	width: 81%;
}


._w-82-pct{
	width: 82%;
}


._w-83-pct{
	width: 83%;
}


._w-84-pct{
	width: 84%;
}


._w-85-pct{
	width: 85%;
}


._w-86-pct{
	width: 86%;
}


._w-87-pct{
	width: 87%;
}


._w-88-pct{
	width: 88%;
}


._w-89-pct{
	width: 89%;
}


._w-90-pct{
	width: 90%;
}


._w-91-pct{
	width: 91%;
}


._w-92-pct{
	width: 92%;
}


._w-93-pct{
	width: 93%;
}


._w-94-pct{
	width: 94%;
}


._w-95-pct{
	width: 95%;
}


._w-96-pct{
	width: 96%;
}


._w-97-pct{
	width: 97%;
}


._w-98-pct{
	width: 98%;
}


._w-99-pct{
	width: 99%;
}


._w-100-pct{
	width: 100%;
}




._w-8-px{
	width: 8px;
}


._w-10-px{
	width: 10px;
}


._w-12-px{
	width: 12px;
}


._w-14-px{
	width: 14px;
}


._w-16-px{
	width: 16px;
}


._w-18-px{
	width: 18px;
}


._w-20-px{
	width: 20px;
}


._w-22-px{
	width: 22px;
}


._w-24-px{
	width: 24px;
}


._w-26-px{
	width: 26px;
}


._w-28-px{
	width: 28px;
}


._w-30-px{
	width: 30px;
}


._w-32-px{
	width: 32px;
}


._w-34-px{
	width: 34px;
}


._w-36-px{
	width: 36px;
}


._w-38-px{
	width: 38px;
}


._w-40-px{
	width: 40px;
}


._w-42-px{
	width: 42px;
}


._w-44-px{
	width: 44px;
}


._w-46-px{
	width: 46px;
}


._w-48-px{
	width: 48px;
}


._w-50-px{
	width: 50px;
}


._w-52-px{
	width: 52px;
}


._w-54-px{
	width: 54px;
}


._w-56-px{
	width: 56px;
}


._w-58-px{
	width: 58px;
}


._w-60-px{
	width: 60px;
}


._w-62-px{
	width: 62px;
}


._w-64-px{
	width: 64px;
}


._w-66-px{
	width: 66px;
}


._w-68-px{
	width: 68px;
}


._w-70-px{
	width: 70px;
}


._w-72-px{
	width: 72px;
}


._w-74-px{
	width: 74px;
}


._w-76-px{
	width: 76px;
}


._w-78-px{
	width: 78px;
}


._w-80-px{
	width: 80px;
}


._w-82-px{
	width: 82px;
}


._w-84-px{
	width: 84px;
}


._w-86-px{
	width: 86px;
}


._w-88-px{
	width: 88px;
}


._w-90-px{
	width: 90px;
}


._w-92-px{
	width: 92px;
}


._w-94-px{
	width: 94px;
}


._w-96-px{
	width: 96px;
}


._w-98-px{
	width: 98px;
}


._w-100-px{
	width: 100px;
}

._w-125-px{
	width: 125px;
}

._w-150-px{
	width: 150px;
}
