._table{
    border-collapse: collapse;
    th, td{
        border: 1px solid #ccc;
        padding: 0.5em;
    }
    tr:nth-child(2n){
        background: #f2fcff;
    }
}


._scroll-table{
    max-height: 70vh;
    overflow: auto;

    thead{
        position: sticky;
        top: 0px;
        left: 0;
        tr{
            background: #fff;
            border: 1px solid #ccc;
        }
    }
}
