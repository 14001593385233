._mg-auto{
	margin: auto;
}

._ml-auto{
	margin-left: auto;
}

._mr-auto{
	margin-right: auto;
}

._mt-auto{
	margin-top: auto;
}

._mb-auto{
	margin-bottom: auto;
}

._no-mg{
	margin: 0;
}

._no-ml{
	margin-left: 0;
}

._no-mt{
	margin-top: 0;
}

._no-mr{
	margin-right: 0;
}

._no-mb{
	margin-bottom: 0;
}

._m-0-25{
	margin: 0.25em;
}
._m-l-0-25{
	margin-left: 0.25em;
}
._m-r-0-25{
	margin-right: 0.25em;
}
._m-t-0-25{
	margin-top: 0.25em;
}
._m-b-0-25{
	margin-bottom: 0.25em;
}



._m-0-5{
	margin: 0.5em;
}
._m-l-0-5{
	margin-left: 0.5em;
}
._m-r-0-5{
	margin-right: 0.5em;
}
._m-t-0-5{
	margin-top: 0.5em;
}
._m-b-0-5{
	margin-bottom: 0.5em;
}



._m-0-75{
	margin: 0.75em;
}
._m-l-0-75{
	margin-left: 0.75em;
}
._m-r-0-75{
	margin-right: 0.75em;
}
._m-t-0-75{
	margin-top: 0.75em;
}
._m-b-0-75{
	margin-bottom: 0.75em;
}



._m-1{
	margin: 1em;
}
._m-l-1{
	margin-left: 1em;
}
._m-r-1{
	margin-right: 1em;
}
._m-t-1{
	margin-top: 1em;
}
._m-b-1{
	margin-bottom: 1em;
}



._m-1-25{
	margin: 1.25em;
}
._m-l-1-25{
	margin-left: 1.25em;
}
._m-r-1-25{
	margin-right: 1.25em;
}
._m-t-1-25{
	margin-top: 1.25em;
}
._m-b-1-25{
	margin-bottom: 1.25em;
}



._m-1-5{
	margin: 1.5em;
}
._m-l-1-5{
	margin-left: 1.5em;
}
._m-r-1-5{
	margin-right: 1.5em;
}
._m-t-1-5{
	margin-top: 1.5em;
}
._m-b-1-5{
	margin-bottom: 1.5em;
}



._m-1-75{
	margin: 1.75em;
}
._m-l-1-75{
	margin-left: 1.75em;
}
._m-r-1-75{
	margin-right: 1.75em;
}
._m-t-1-75{
	margin-top: 1.75em;
}
._m-b-1-75{
	margin-bottom: 1.75em;
}



._m-2{
	margin: 2em;
}
._m-l-2{
	margin-left: 2em;
}
._m-r-2{
	margin-right: 2em;
}
._m-t-2{
	margin-top: 2em;
}
._m-b-2{
	margin-bottom: 2em;
}



._m-2-25{
	margin: 2.25em;
}
._m-l-2-25{
	margin-left: 2.25em;
}
._m-r-2-25{
	margin-right: 2.25em;
}
._m-t-2-25{
	margin-top: 2.25em;
}
._m-b-2-25{
	margin-bottom: 2.25em;
}



._m-2-5{
	margin: 2.5em;
}
._m-l-2-5{
	margin-left: 2.5em;
}
._m-r-2-5{
	margin-right: 2.5em;
}
._m-t-2-5{
	margin-top: 2.5em;
}
._m-b-2-5{
	margin-bottom: 2.5em;
}



._m-2-75{
	margin: 2.75em;
}
._m-l-2-75{
	margin-left: 2.75em;
}
._m-r-2-75{
	margin-right: 2.75em;
}
._m-t-2-75{
	margin-top: 2.75em;
}
._m-b-2-75{
	margin-bottom: 2.75em;
}



._m-3{
	margin: 3em;
}
._m-l-3{
	margin-left: 3em;
}
._m-r-3{
	margin-right: 3em;
}
._m-t-3{
	margin-top: 3em;
}
._m-b-3{
	margin-bottom: 3em;
}



._m-3-25{
	margin: 3.25em;
}
._m-l-3-25{
	margin-left: 3.25em;
}
._m-r-3-25{
	margin-right: 3.25em;
}
._m-t-3-25{
	margin-top: 3.25em;
}
._m-b-3-25{
	margin-bottom: 3.25em;
}



._m-3-5{
	margin: 3.5em;
}
._m-l-3-5{
	margin-left: 3.5em;
}
._m-r-3-5{
	margin-right: 3.5em;
}
._m-t-3-5{
	margin-top: 3.5em;
}
._m-b-3-5{
	margin-bottom: 3.5em;
}



._m-3-75{
	margin: 3.75em;
}
._m-l-3-75{
	margin-left: 3.75em;
}
._m-r-3-75{
	margin-right: 3.75em;
}
._m-t-3-75{
	margin-top: 3.75em;
}
._m-b-3-75{
	margin-bottom: 3.75em;
}



._m-4{
	margin: 4em;
}
._m-l-4{
	margin-left: 4em;
}
._m-r-4{
	margin-right: 4em;
}
._m-t-4{
	margin-top: 4em;
}
._m-b-4{
	margin-bottom: 4em;
}



._m-4-5{
	margin: 4.5em;
}
._m-l-4-5{
	margin-left: 4.5em;
}
._m-r-4-5{
	margin-right: 4.5em;
}
._m-t-4-5{
	margin-top: 4.5em;
}
._m-b-4-5{
	margin-bottom: 4.5em;
}



._m-5{
	margin: 5em;
}
._m-l-5{
	margin-left: 5em;
}
._m-r-5{
	margin-right: 5em;
}
._m-t-5{
	margin-top: 5em;
}
._m-b-5{
	margin-bottom: 5em;
}



._m-7-5{
	margin: 7.5em;
}
._m-l-7-5{
	margin-left: 7.5em;
}
._m-r-7-5{
	margin-right: 7.5em;
}
._m-t-7-5{
	margin-top: 7.5em;
}
._m-b-7-5{
	margin-bottom: 7.5em;
}



._m-10{
	margin: 10em;
}
._m-l-10{
	margin-left: 10em;
}
._m-r-10{
	margin-right: 10em;
}
._m-t-10{
	margin-top: 10em;
}
._m-b-10{
	margin-bottom: 10em;
}
