._profile-card{
    width: 100%;
    max-width: 786px;
    min-height: 100vh;
}

._adduser{
    width: 100%;
    max-width: 448px;
    height: 100vh;
    transition: ease-in-out 0.5s;
}
