._text-small{
    font-size: 0.8em;
}

._text-normal{
    font-size: 1em;
}

._text-medium{
    font-size: 1.2em;
}


._text-8-px{
	font-size: 8px;
}


._text-10-px{
	font-size: 10px;
}


._text-12-px{
	font-size: 12px;
}


._text-14-px{
	font-size: 14px;
}


._text-16-px{
	font-size: 16px;
}


._text-18-px{
	font-size: 18px;
}


._text-20-px{
	font-size: 20px;
}


._text-22-px{
	font-size: 22px;
}


._text-24-px{
	font-size: 24px;
}


._text-26-px{
	font-size: 26px;
}


._text-28-px{
	font-size: 28px;
}


._text-30-px{
	font-size: 30px;
}


._text-32-px{
	font-size: 32px;
}


._text-34-px{
	font-size: 34px;
}


._text-36-px{
	font-size: 36px;
}


._text-38-px{
	font-size: 38px;
}


._text-40-px{
	font-size: 40px;
}
