$mylan-color: #005496;
$theme-color: $mylan-color;  // mylan logo blue

$pc-vp:           1280px;
$pad-vp:          992px;
$mobile-vp:       768px;
$small-mobile-vp: 448px;

$color-0: #0000ff;  // light blue
$color-1: #5a5a5a;  // deep gray
$color-2: #989898;  // gray
$color-3: #cccccc;  // light gray
$color-4: #ff6655;  // light crimson
$color-5: #00ff00;  // green
$color-6: #e8eb00;  // yellow
$nav-color: #34A1C7;
*{
    outline: none !important;
}


._app{
    width: calc( 100vw - 300px );
}

