._text-center{
    text-align: center;
}

._text-right{
    text-align: right;
}

._text-justified{
    text-align: justify;
}

._text-left{
    text-align: left;
}
._upper{
    text-transform: uppercase;
}
