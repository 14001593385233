@import "./absolute.scss";
@import "./relative.scss";
@import "./fixed.scss";
@import "./sticky.scss";


._t-0{
    bottom: unset;
    top: 0;
}

._l-0{
    right: unset;
    left: 0;
}

._r-0{
    left: unset;
    right: 0;
}

._b-0{
    top: unset;
    bottom: 0;
}



._z-index-0{
	z-index: 0;
}


._z-index-1{
	z-index: 1;
}


._z-index-2{
	z-index: 2;
}


._z-index-3{
	z-index: 3;
}


._z-index-4{
	z-index: 4;
}


._z-index-5{
	z-index: 5;
}


._z-index-6{
	z-index: 6;
}


._z-index-7{
	z-index: 7;
}


._z-index-8{
	z-index: 8;
}


._z-index-9{
	z-index: 9;
}


._z-index-10{
	z-index: 10;
}


._z-index-11{
	z-index: 11;
}


._z-index-12{
	z-index: 12;
}


._z-index-13{
	z-index: 13;
}


._z-index-14{
	z-index: 14;
}


._z-index-15{
	z-index: 15;
}


._z-index-16{
	z-index: 16;
}


._z-index-17{
	z-index: 17;
}


._z-index-18{
	z-index: 18;
}


._z-index-19{
	z-index: 19;
}


._z-index-20{
	z-index: 20;
}


._z-index-21{
	z-index: 21;
}


._z-index-22{
	z-index: 22;
}


._z-index-23{
	z-index: 23;
}


._z-index-24{
	z-index: 24;
}


._z-index-25{
	z-index: 25;
}


._z-index-26{
	z-index: 26;
}


._z-index-27{
	z-index: 27;
}


._z-index-28{
	z-index: 28;
}


._z-index-29{
	z-index: 29;
}


._z-index-30{
	z-index: 30;
}


._z-index-31{
	z-index: 31;
}


._z-index-32{
	z-index: 32;
}


._z-index-33{
	z-index: 33;
}


._z-index-34{
	z-index: 34;
}


._z-index-35{
	z-index: 35;
}


._z-index-36{
	z-index: 36;
}


._z-index-37{
	z-index: 37;
}


._z-index-38{
	z-index: 38;
}


._z-index-39{
	z-index: 39;
}


._z-index-40{
	z-index: 40;
}


._z-index-41{
	z-index: 41;
}


._z-index-42{
	z-index: 42;
}


._z-index-43{
	z-index: 43;
}


._z-index-44{
	z-index: 44;
}


._z-index-45{
	z-index: 45;
}


._z-index-46{
	z-index: 46;
}


._z-index-47{
	z-index: 47;
}


._z-index-48{
	z-index: 48;
}


._z-index-49{
	z-index: 49;
}


._z-index-50{
	z-index: 50;
}
		
