
._border-radius-8-px{
	border-radius: 8px;
}


._border-radius-10-px{
	border-radius: 10px;
}


._border-radius-12-px{
	border-radius: 12px;
}


._border-radius-14-px{
	border-radius: 14px;
}


._border-radius-16-px{
	border-radius: 16px;
}


._border-radius-18-px{
	border-radius: 18px;
}


._border-radius-20-px{
	border-radius: 20px;
}


._border-radius-22-px{
	border-radius: 22px;
}


._border-radius-24-px{
	border-radius: 24px;
}


._border-radius-26-px{
	border-radius: 26px;
}


._border-radius-28-px{
	border-radius: 28px;
}


._border-radius-30-px{
	border-radius: 30px;
}


._border-radius-32-px{
	border-radius: 32px;
}


._border-radius-34-px{
	border-radius: 34px;
}


._border-radius-36-px{
	border-radius: 36px;
}


._border-radius-38-px{
	border-radius: 38px;
}


._border-radius-40-px{
	border-radius: 40px;
}

._border-radius-50-pct{
	border-radius: 50%;
}
