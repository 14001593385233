
._p-0-25{
	padding: 0.25em;
}
._p-l-0-25{
	padding-left: 0.25em;
}
._p-r-0-25{
	padding-right: 0.25em;
}
._p-t-0-25{
	padding-top: 0.25em;
}
._p-b-0-25{
	padding-bottom: 0.25em;
}


._p-0-5{
	padding: 0.5em;
}
._p-l-0-5{
	padding-left: 0.5em;
}
._p-r-0-5{
	padding-right: 0.5em;
}
._p-t-0-5{
	padding-top: 0.5em;
}
._p-b-0-5{
	padding-bottom: 0.5em;
}


._p-0-75{
	padding: 0.75em;
}
._p-l-0-75{
	padding-left: 0.75em;
}
._p-r-0-75{
	padding-right: 0.75em;
}
._p-t-0-75{
	padding-top: 0.75em;
}
._p-b-0-75{
	padding-bottom: 0.75em;
}


._p-1{
	padding: 1em;
}
._p-l-1{
	padding-left: 1em;
}
._p-r-1{
	padding-right: 1em;
}
._p-t-1{
	padding-top: 1em;
}
._p-b-1{
	padding-bottom: 1em;
}


._p-1-25{
	padding: 1.25em;
}
._p-l-1-25{
	padding-left: 1.25em;
}
._p-r-1-25{
	padding-right: 1.25em;
}
._p-t-1-25{
	padding-top: 1.25em;
}
._p-b-1-25{
	padding-bottom: 1.25em;
}


._p-1-5{
	padding: 1.5em;
}
._p-l-1-5{
	padding-left: 1.5em;
}
._p-r-1-5{
	padding-right: 1.5em;
}
._p-t-1-5{
	padding-top: 1.5em;
}
._p-b-1-5{
	padding-bottom: 1.5em;
}


._p-1-75{
	padding: 1.75em;
}
._p-l-1-75{
	padding-left: 1.75em;
}
._p-r-1-75{
	padding-right: 1.75em;
}
._p-t-1-75{
	padding-top: 1.75em;
}
._p-b-1-75{
	padding-bottom: 1.75em;
}


._p-2{
	padding: 2em;
}
._p-l-2{
	padding-left: 2em;
}
._p-r-2{
	padding-right: 2em;
}
._p-t-2{
	padding-top: 2em;
}
._p-b-2{
	padding-bottom: 2em;
}


._p-2-25{
	padding: 2.25em;
}
._p-l-2-25{
	padding-left: 2.25em;
}
._p-r-2-25{
	padding-right: 2.25em;
}
._p-t-2-25{
	padding-top: 2.25em;
}
._p-b-2-25{
	padding-bottom: 2.25em;
}


._p-2-5{
	padding: 2.5em;
}
._p-l-2-5{
	padding-left: 2.5em;
}
._p-r-2-5{
	padding-right: 2.5em;
}
._p-t-2-5{
	padding-top: 2.5em;
}
._p-b-2-5{
	padding-bottom: 2.5em;
}


._p-2-75{
	padding: 2.75em;
}
._p-l-2-75{
	padding-left: 2.75em;
}
._p-r-2-75{
	padding-right: 2.75em;
}
._p-t-2-75{
	padding-top: 2.75em;
}
._p-b-2-75{
	padding-bottom: 2.75em;
}


._p-3{
	padding: 3em;
}
._p-l-3{
	padding-left: 3em;
}
._p-r-3{
	padding-right: 3em;
}
._p-t-3{
	padding-top: 3em;
}
._p-b-3{
	padding-bottom: 3em;
}


._p-3-25{
	padding: 3.25em;
}
._p-l-3-25{
	padding-left: 3.25em;
}
._p-r-3-25{
	padding-right: 3.25em;
}
._p-t-3-25{
	padding-top: 3.25em;
}
._p-b-3-25{
	padding-bottom: 3.25em;
}


._p-3-5{
	padding: 3.5em;
}
._p-l-3-5{
	padding-left: 3.5em;
}
._p-r-3-5{
	padding-right: 3.5em;
}
._p-t-3-5{
	padding-top: 3.5em;
}
._p-b-3-5{
	padding-bottom: 3.5em;
}


._p-3-75{
	padding: 3.75em;
}
._p-l-3-75{
	padding-left: 3.75em;
}
._p-r-3-75{
	padding-right: 3.75em;
}
._p-t-3-75{
	padding-top: 3.75em;
}
._p-b-3-75{
	padding-bottom: 3.75em;
}


._p-4{
	padding: 4em;
}
._p-l-4{
	padding-left: 4em;
}
._p-r-4{
	padding-right: 4em;
}
._p-t-4{
	padding-top: 4em;
}
._p-b-4{
	padding-bottom: 4em;
}


._p-4-5{
	padding: 4.5em;
}
._p-l-4-5{
	padding-left: 4.5em;
}
._p-r-4-5{
	padding-right: 4.5em;
}
._p-t-4-5{
	padding-top: 4.5em;
}
._p-b-4-5{
	padding-bottom: 4.5em;
}


._p-5{
	padding: 5em;
}
._p-l-5{
	padding-left: 5em;
}
._p-r-5{
	padding-right: 5em;
}
._p-t-5{
	padding-top: 5em;
}
._p-b-5{
	padding-bottom: 5em;
}


._p-7-5{
	padding: 7.5em;
}
._p-l-7-5{
	padding-left: 7.5em;
}
._p-r-7-5{
	padding-right: 7.5em;
}
._p-t-7-5{
	padding-top: 7.5em;
}
._p-b-7-5{
	padding-bottom: 7.5em;
}


._p-10{
	padding: 10em;
}
._p-l-10{
	padding-left: 10em;
}
._p-r-10{
	padding-right: 10em;
}
._p-t-10{
	padding-top: 10em;
}
._p-b-10{
	padding-bottom: 10em;
}
		
