._shadow{
    box-shadow: 1px 1px 15px 2px rgba(0,0,0,0.2);
}

._shadow-blur{
    box-shadow: 1px 1px 5px 2px rgba(169,169,169,0.2);
}

._shadow-hover{
    transition: ease-in-out 0.25s;
}

._shadow-hover:hover{
    box-shadow: 1px 1px 15px 2px rgba(0,0,0,0.2);
}


._shadow-right{
    box-shadow: 1px 0px 15px 2px rgba(0,0,0,0.1);
}

._vertical-bar-shadow{
    box-shadow: 1px 0px 15px 2px rgba(0,0,0,0.25);
}
